const language = {
    header: {
        en: {
            auth: "Authorization",
            login: "Log In",
            email: "E-mail",
            password: "Password",
            registration: "Registration",
            forgotPassword: "Forgot Your Password",
            home: "Home",
            catalogue: "Catalogue",
            about: "About",
            blog: "Blog",
            calculator: "Calculator",
            contact: "Contact",
            dealerPage: "Dealer Page",
            autoMarket: "Auto Market"
        },
        geo: {
            auth: "ავტორიზაცია",
            login: "შესვლა",
            email: "ელ. ფოსტა",
            password: "პაროლი",
            registration: "რეგისტრაცია",
            forgotPassword: "დაგავიწყდათ პაროლი",
            home: "მთავარი",
            catalogue: "კატალოგი",
            about: "ჩვენს შესახებ",
            blog: "ბლოგი",
            calculator: "კალკულატორი",
            contact: "კონტაქტი",
            dealerPage: "დილერის გვერდი",
            autoMarket: "ავტო მარკეტი"
        }
    },
    footer: {
        en: {
            company: "Company",
            about: "About Us",
            careers: "Careers",
            blog: "Blog",
            faqs: "FAQs",
            contact: "Contact",
            help: "Help",
            sendMessage: "Send a Message",
            callRequest: "Request a Call",
            brands: "Brands",
            categories: "Categories",
            coupe: "Coupe",
            sedan: "Sedan",
            stationVagon: "Station Vagon",
            suv: "SUV",
            minivan: "Minivan",
            van: "Van",
            convertible: "Convertible",
            limousine: "Limousine",
            email: "Enter your email",
            subscribe: "Subscribe",
            application: "Mobile Application",
            download: "Download",
            terms: "Terms & Conditions",
            security: "Security Policy"
        },
        geo: {
            company: "კომპანია",
            about: "ჩვენს შესახებ",
            careers: "კარიერა",
            blog: "ბლოგი",
            faqs: "ხშირად დასმული კითხვები",
            contact: "კონტაქტი",
            sendMessage: "შეტყობინების გაგზავნა",
            callRequest: "ზარის მოთხოვნა",
            brands: "ბრენდი",
            categories: "კატეგორია",
            coupe: "კუპე",
            sedan: "სედან",
            stationVagon: "Station Vagon",
            suv: "სპორტული უნივერსალი",
            minivan: "მინივენი",
            van: "Van",
            convertible: "Convertible",
            limousine: "Limousine",
            email: "ჩაწერეთ თქვენი ელ-ფოსტა",
            subscribe: "გამოწერა",
            application: "მობილური აპლიკაცო",
            download: "გადმოწერა",
            terms: "წესები და პირობები",
            security: "უსაფრთხოების პოლიტიკა"
        }
    },
    
    home: {
        en: {
            chooseCar: "Choose your preferred car",
            suv: "SUV",
            sedan: "Sedan",
            coupe: "Coupe",
            hybrid: "Hybrid",
            compact: "Compact",
            whyCarspace: {
                title: "Why Carspace",
                price: {
                    title: "Best Price",
                    description: "With our help, you can import a car at an average of 30% less cost."
                },
                support: {
                    title: "Continuous Support",
                    description: "Our specialists provide high-quality consultations on any topic of interest to you."
                },
                benefits: {
                    title: "Additional Benefits",
                    description: "We regularly offer special deals and promotions to our partner dealers. At Carspace, we strive to always guarantee the best offers on the market, fully addressing our customers' needs."
                },
                safety: {
                    title: "Safety",
                    description: "Transportation with Carspace is insured, providing additional security and ensuring the safety of your vehicles. By working with us, you benefit from a high-quality and secure transportation service that meets all your requirements."
                }
            },
            topRated: {
                title: "Top Rated Vehicles",
                currentPrice: "Current Price",
                purchaseNow: "Purchase Now",
                views: "Views",
                lot: "Lot#"
            },
            premiumBrands: "Find Premium Vehicle Brands",
            calculator: {
                title: "Transportation Calculator",
                subtitle: "Use the Calculator to Estimate Your Average Shipping Costs to Poti",
                auction: "Auction",
                location: "Location",
                port: "Port",
                destination: "Destination",
                shipping: "Sea Shipping",
                domesticShipping: "Domestic Shipping",
                total: "Total",
                selectAuction: "Select Auction",
                selectLocation: "Select Location",
                selectPort: "Select Port",
                selectDestination: "Select Destination",
                calculate: "Calculate"
            },
            news: "Interesting News",
            noNews: "News not found",
            contact: {
                title: "Contact",
                consultation: "Get Transport Advisory",
                details: "Contact Details",
                address: "Aghmashenebeli Alley 19, Tbilisi",
                hours: "Working Hours",
                days: "Monday - Friday: 9:00 AM - 7:00 PM",
                follow: "Follow Us",
                name: "Name",
                surname: "Surname",
                email: "Email",
                phone: "Phone",
                message: "Message"
            }
        },
        geo: {
            chooseCar: "აარჩიე სასურველი ავტომობილი",
            suv: "სპორტული უნივერსალი",
            sedan: "სედანი",
            coupe: "კუპე",
            hybrid: "ჰიბრიდი",
            compact: "კომპაქტი",
            whyCarspace: {
                title: "რატომ ქარსფეისი",
                price: {
                    title: "საუკეთესო ფასი",
                    description: "- ჩვენი დახმარებით საშუალოდ 30%-ით უფრო დაბალ ფასად შეგიძლიათ ჩამოიყვანოთ ავტომობილი."
                },
                support: {
                    title: "მუდმივი მხარდაჭერა",
                    description: "ჩვენი სპეციალისტები გაგიწევენ მაღალი დონის კონსულტაციებს ნებისმიერ, თქვენთვის საინტერესო საკითხზე."
                },
                benefits: {
                    title: "დამატებითი ბენეფიტები",
                    description: "ჩვენს პარტნიორ დილერებს რეგულარულად ვთავაზობთ სპეციალურ შეთავაზებებსა და აქციებს. Carspace-ში ვცდილობთ, ყოველთვის ვიყოთ ბაზარზე საუკეთესო შეთავაზებების გარანტი და მაქსიმალურად ვაკმაყოფილებდეთ მომხმარებლის საჭიროებებს."
                },
                safety: {
                    title: "უსაფრთხოება",
                    description: "Carspace-ში ტრანსპორტირება დაზღვეულია, რაც უზრუნველყოფს დამატებით უსაფრთხოებას და თქვენი სატრანსპორტო საშუალებების დაცვას. ჩვენთან მუშაობით თქვენ იღებთ მაღალი ხარისხის და უსაფრთხო ტრანსპორტირების სერვისს, რომელიც სრულად პასუხობს თქვენს მოთხოვნებს."
                }
            },
            topRated: {
                title: "რჩეული ავტომობილები",
                currentPrice: "მიმდინარე ფასი",
                purchaseNow: "ახლავე ყიდვა",
                views: "ნახვა",
                lot: "ლოტი"
            },
            premiumBrands: "აღმოაჩინე პრემიუმ ბრენდები",
            calculator: {
                title: "ტრანსპორტირების კალკულატორი",
                subtitle: "კალკულატორის დახმარებით გამოთვალე ტრანსპორტირების საშუალო ხარჯი ფოთამდე.",
                auction: "აუქციონი",
                location: "ლოკაცია",
                port: "პორტი",
                destination: "დანიშნულების ადგილი",
                shipping: "საზღვაო გადაზიდვა",
                domesticShipping: "შიდა გადაზიდვა",
                total: "ჯამი",
                selectAuction: "მონიშნეთ აუქციონი",
                selectLocation: "მონიშნეთ ლოკაცია",
                selectPort: "მონიშნეთ პორტი",
                selectDestination: "მონიშნეთ დანიშნულების ადგილი",
                calculate: "გამოთვლა"
            },
            news: "საინტერესო სიახლეები",
            noNews: "სიახლეები ვერ მოიძებნა",
            contact: {
                title: "კონტაქტი",
                consultation: "მიიღე კონსულტაცია",
                details: "კონტაქტის დეტალები",
                address: "აღმაშენებლის ხეივანი N19, თბილისი",
                hours: "სამუშაო საათები",
                days: "ორშაბათი - პარასკევი: 9:00 AM - 7:00 PM",
                follow: "გამოგვყევით",
                name: "სახელი",
                surname: "გვარი",
                email: "ელ. ფოსტა",
                phone: "ტელეფონი",
                message: "შეტყობინება"
            }
        }
    },

    catalogue: {
        en: {
            manufacturer: "Manufacturer",
            model: "Car Model",
            mileage: "Mileage",
            year: "Year",
            additionalFilter: "Additional Filters",
            condition: "Condition",
            fuelType: "Fuel Type",
            transmission: "Transmission",
            drive: "Drive Type",
            sale: "Sale Type",
            enterVin: "Enter VIN code or LOT number",
            day: "Day",
            hour: "Hour",
            minute: "Minute",
            purchaseNow: "Purchase Now",
            currentPrice: "Current Price",
            notFound: "Vehicle not found",
            showing: "Showing Results",
            of: "Of",
            lot: "Lot#"
        },
        geo: {
            manufacturer: "მწარმოებელი",
            model: "მოდელი",
            mileage: "გარბენი",
            year: "წელი",
            additionalFilter: "დამატებითი ფილტრი",
            condition: "მდგომარეობა",
            fuelType: "საწვავის ტიპი",
            transmission: "ტრანსმისია",
            drive: "წამყვანი თვლები",
            sale: "გაყიდვის ტიპი",
            enterVin: "Enter VIN code or LOT number",
            day: "დღე",
            hour: "საათი",
            minute: "წუთი",
            purchaseNow: "ახლავე ყიდვა",
            currentPrice: "მიმდინარე ფასი",
            notFound: "ავტომობილი ვერ მოიძებნა",
            showing: "შედეგების ჩვენება",
            of: "დან",
            lot: "ლოტი"
        }
    },

    aboutUs: {
        en: {
            title: "About Us",
            description: `Carspace was founded in 2020 by a highly qualified team with over 10 years of experience in the
                car transportation industry.
                Our goal is to offer customers transparent, reliable, and high-quality services.
                Professionalism and attention to details give us the opportunity to continually improve our services
                and remain leaders in the industry.
                To date, we have transported over 10,000 vehicles, which proves the reliability and experience of
                our services`,
            vehicleSearch: {
                title: "Vehicle Search",
                description: `Carspace enables the search for vehicles at leading auctions such as: Copart, IAAI,
                    Manheim, Edge Pipeline, and others. We handle the auction process, payment, and all
                    necessary documentation. Additionally, we arrange transportation to the Port and send the
                    vehicle by container to the destination.`,
            },
            specialServices: {
                title: "Special Services for Auto Dealers",
                description: `Carspace offers auto dealers personalized support and special rates for transportation.
                    We provide access to auto auction codes such as Copart, IAAI, Manheim, Edge Pipeline,
                    and others. Our system helps you manage vehicles more easily. The service includes:
                    automatic notifications, transportation insurance, a personal manager, and unlimited
                    Carfax reports.`
            },
            vehicleCutting: {
                title: "Vehicle Cutting & parting out services",
                description: `Carspace provides vehicle cutting or parting out services. Up to 10-12 vehicles can be
                    placed in a container, depending on the size of the vehicle. For personal request, we can
                    place additional auto parts in the container.`
            },
            serviceIncludes: {
                title: "Our services include:",
                details: [
                    "Shipping of passenger cars, SUVs, motorcycles, ATVs, jet skis, boats, and ships.",
                    "Transportation of small specialized machinery and, if necessary, large/heavy machinery.",
                    "Vehicle transportation from any location in the U.S. to our warehouse, followed by loading into containers and exporting from the U.S."
                ],
                description: `With Carspace, transportation is insured, providing additional security for your vehicles and
                    shipped cargo. By working with us, you will receive high-quality and safe services that fully satisfies
                    your needs`,
            },
            leadForm: {
                title: "Fill out a simple form and become our partner",
                name: "First Name",
                surname: "Last Name",
                email: "Email",
                phone: "Phone",
                message: "Message",
                messagePlaceholder: "Leave Us a Message"
            },
            years: "4 years in the market",
            customers: "Over 2,000 customers",
            vehicles: "Over 10,000 vehicles",
            experience: "Over 10 years of experience"
        },
        geo: {
            title: "ჩვენს შესახებ",
            description: `კომპანია Carspace/ქარსფეისი დაარსდა 2020 წელს მაღალკვალიფიციური გუნდის მიერ,
                რომელსაც 10 წელზე მეტი გამოცდილება აქვს ავტომობილების გადაზიდვის სფეროში.
                ჩვენი მიზანია, მომხმარებლებს შევთავაზოთ გამჭვირვალე, საიმედო და მაღალი ხარისხის
                სერვისი.
                პროფესიონალიზმი და დეტალებზე ორიენტირება გვაძლევს შესაძლებლობას, რომ
                მუდმივად გავაუმჯობესოთ ჩვენი სერვისები და დავრჩეთ ლიდერებად ინდუსტრიაში.
                ამ დროისთვის ჩამოვიყვანეთ 10 000-ზე მეტი ავტომობილი, რაც ჩვენი მომსახურების
                საიმედოობასა და გამოცდილებას ადასტურებს.`,
            vehicleSearch: {
                title: "ავტომობილის მოძიება",
                description: `Carspace შესაძლებელს ხდის ავტომობილების მოძიებას წამყვან აუქციონებზე,
                    როგორებიცაა: Copart, IAAI, Manheim, Edge Pipeline და სხვა. ვუზრუნველყოფთ
                    აუქციონის პროცესს, ანგარიშსწორებასა და ყველა საჭირო დოკუმენტაციას. ასევე,
                    ვგეგმავთ ტრანსპორტირებას პორტამდე და ვგზავნით კონტეინერით დანიშნულების
                    ადგილამდე.`,
            },
            specialServices: {
                title: "სპეციალური სერვისები ავტო დილერებისთვის",
                description: `Carspace სთავაზობს ავტო დილერებს პერსონალურ მხარდაჭერას და სპეციალურ
                    ტარიფებს ტრანსპორტირებაზე. ჩვენ გაწვდით ავტო აუქციონის კოდებს: Copart, IAAI,
                    Manheim, Edge Pipeline და სხვა. ჩვენი სისტემა გეხმარებათ ავტომობილების
                    მარტივად მართვაში. სერვისი მოიცავს: ავტომატურ შეტყობინებებს,
                    ტრანსპორტირების დაზღვევას, პირად მენეჯერს და ულიმიტო Carfax ანგარიშებს.`
            },
            vehicleCutting: {
                title: "ავტომობილის დაჭრა-დანაწევრება",
                description: `Carspace უზრუნველყოფს ავტომობილების დაჭრა-დანაწევრებას. კონტეინერში
                    შესაძლოა 10-12 ავტომობილის განთავსება, რაც დამოკიდებულია ავტომობილის
                    ზომაზე. შეგვიძლია, თქვენი მოთხოვნით, დამატებითი ნაწილების განთავსება.`
            },
            serviceIncludes: {
                title: "ჩვენი მომსახურება მოიცავს:",
                details: [
                    "მსუბუქი ავტომობილების, ჯიპების, მოტოციკლეტების, კვადროციკლების, წყლის სკუტერების, კატერებისა და ნავების გადაზიდვას.",
                    "მცირე სპეც ტექნიკის და, საჭიროების შემთხვევაში, დიდი/მძიმე ტექნიკის გადაზიდვას.",
                    "ავტომობილების გადაზიდვა ამერიკის ნებისმიერი წერტილიდან ჩვენს საწყობამდე, შემდეგ კი, კონტეინერში ჩატვირთვას და ექსპორტს ამერიკიდან."
                ],
                description: `Carspace-თან ერთად ტრანსპორტირება დაზღვეულია, რაც დამატებით უსაფრთხოებას
                    უზრუნველყოფს თქვენს სატრანსპორტო საშუალებებზე და გადაზიდულ ტვირთზე. ჩვენთან
                    თანამშრომლობით მიიღებთ მაღალი ხარისხის და უსაფრთხო სერვისს, რომელიც სრულად
                    აკმაყოფილებს თქვენს საჭიროებებს`,
            },
            leadForm: {
                title: "შეავსე მარტივი ფორმა და გახდი ჩვენი პარტნიორი",
                name: "სახელი",
                surname: "გვარი",
                email: "ელ. ფოსტა",
                phone: "ტელეფონი",
                message: "შეტყობინება",
                messagePlaceholder: "დაგვიტოვეთ შეტყობინება"
            },
            years: "4 წელი ბაზარზე",
            customers: "2,000-ზე მეტი მომხმარებელი",
            vehicles: "10,000-ზე მეტი ავტომობილი",
            experience: "10 წელზე მეტი გამოცდილება"
        }
    },

    standard: {
        en: {
            viewAll: "View All",
            send: "Send",
            search: "Search"
        },
        geo: {
            viewAll: "ყველას ნახვა",
            send: "გაგზავნა",
            search: "ძებნა"
        }
    }
}

export default language