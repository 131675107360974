import AsideModal from "./Components/AsideModal/AsideModal"
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import LeadForm from "./pages/LeadForm";
import { useEffect } from "react";
import Users from "./pages/Users";
import Transactions from "./pages/Transactions";
import Consultation from "./pages/Consultation";
import BidsHistory from "./pages/BidsHistory";
import BuyNowHistory from "./pages/BuyNowHistory";
import Subscribers from "./pages/Subscribers";

const AdminApp = ({setIsAdminPage}) => {

    useEffect(() => {
        setIsAdminPage(true);
    }, [])

    return(
        <div className="admin-page-main-container">
            <AsideModal />
            <div className="admin-pages-wrapper">
                <Route path="/admin-dashboard/lead-forms"><LeadForm /></Route>
                <Route path="/admin-dashboard/users"><Users /></Route>
                <Route path="/admin-dashboard/transactions"><Transactions /></Route>
                <Route path="/admin-dashboard/consultation"><Consultation /></Route>
                <Route path="/admin-dashboard/bids"><BidsHistory /></Route>
                <Route path="/admin-dashboard/buy-now"><BuyNowHistory /></Route>
                <Route path="/admin-dashboard/subscriptions"><Subscribers /></Route>
            </div>
        </div>
    )
}

export default AdminApp