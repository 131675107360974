import { useEffect, useState } from "react"
import "../stylesheets/datatable.css"
import axios from "axios";
import { core } from "../../../core";

const BidsHistory = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        setIsLoading(true);
        setData([]);
        axios(`/api/bids-history?page=${page}&limit=${limit}&keyword=${keyword}`)
            .then((res) => {
                setIsLoading(false);
                setData(res.data.data);
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }, [keyword]);

    return(
        <div className="admin-pager-container">
            <h3 className="admin-pages-title">Bids History</h3>
            <div className="data-table-filter-container">
                <div className="data-table-filter-sides">
                    <input type="text" placeholder="Search by keyword" className="search-by-keyword-box" onKeyDown={(e) => e.keyCode == 13 && setKeyword(searchFieldValue)} value={searchFieldValue} onChange={(e) => setSearchFieldValue(e.target.value)} />
                    <img onClick={() => setKeyword(searchFieldValue)} className="search-by-keyword-loop" src="/static/icons/loop.png"/>
                </div>
                <div className="data-table-filter-sides">
                    
                </div>
            </div>
            <table className="data-table-container">
                <thead>
                    <tr>
                        <th>Mark</th>
                        <th>Model</th>
                        <th>VIN</th>
                        <th>Name</th>
                        <th>Surname</th>
                        <th>Username</th>
                        <th>Phone</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Auction</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading && <tr><td colSpan={10}><div className="loader-container"><div className="data-loader"></div></div></td></tr>}
                    {(!isLoading && data.length == 0) && <tr><td style={{textAlign: "center"}} colSpan={11}>Records not found</td></tr>}
                    {
                        data.map((item) => {
                            return(
                                <tr key={item.id}>
                                    <td>{item.mark}</td>
                                    <td>{item.model}</td>
                                    <td>{item.vin}</td>
                                    <td>{item.name}</td>
                                    <td>{item.surname}</td>
                                    <td>{item.username}</td>
                                    <td>{item.phone}</td>
                                    <td>{item.amount}</td>
                                    <td>{core.utcTimeToReal(item.date)}</td>
                                    <td>{item.auction}</td>
                                    <td>
                                        more
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default BidsHistory