import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

const MillageSelector = ({active, data, onChangeFunction, parameterName, placeHolder, disabled, disabledBtnTitle, type, formData, setFormData}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [millage, setMillage] = useState({from: "0", to: "999999"});

    useEffect(() => {
        setMillage({from: formData.millageFrom, to: formData.millageTo})
    }, [])

    return(
        <div className="form-control-select">
            <div className={`form-control-select-box ${disabled ? 'disabled-dropdown' : ''}`} title={disabled ? disabledBtnTitle : ""} onClick={() => {
                    if(!disabled) setShowDropdown(!showDropdown);
                }}>
                    <p>{active === "all" ? placeHolder : active}</p>
                    <img src="/static/icons/down-icon.svg" alt="down" className="select-dropdown-icon" />
            </div>
            {(showDropdown) && <OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
                <div className="target-box"></div>
                <div className="form-control-select-dropdown millage-selector">
                    <div className="miles-to-km-switcher">
                        <span style={{marginRight: "12px"}}>მილი</span>
                            <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                            </label>
                        <span style={{marginLeft: "12px"}}>კმ</span>
                    </div>
                    <div className="millage-input-range-content">
                        <div className="millage-input-box">
                            <span>დან</span>
                            <input value={`${millage.from}`} onChange={(e) => setMillage((prev) => ({...prev, from: e.target.value}))} type="text" className="no-border"/>
                        </div>
                        <div className="millage-input-box">
                            <span>მდე</span>
                            <input value={`${millage.to}`} onChange={(e) => setMillage((prev) => ({...prev, to: e.target.value}))} type="text" className="no-border"/>
                        </div>
                    </div>
                    <div className="range-slider-two-switcher">
                        <ul className="range-selector-dropdown-column">
                            {millage.to > 0 && <li className={millage.from == 0 ? "active" : ""} onClick={() => setMillage((prev) => ({...prev, from: 0}))}>0</li>}                            
                            {millage.to > 30000 && <li className={millage.from == 30000 ? "active" : ""} onClick={() => setMillage((prev) => ({...prev, from: 30000}))}>30,000</li>}
                            {millage.to > 60000 && <li className={millage.from == 60000 ? "active" : ""} onClick={() => setMillage((prev) => ({...prev, from: 60000}))}>60,000</li>}
                            {millage.to > 90000 && <li className={millage.from == 90000 ? "active" : ""} onClick={() => setMillage((prev) => ({...prev, from: 90000}))}>90,000</li>}
                            {millage.to > 120000 && <li className={millage.from == 120000 ? "active" : ""} onClick={() => setMillage((prev) => ({...prev, from: 120000}))}>120,000</li>}
                            {millage.to > 150000 && <li className={millage.from == 150000 ? "active" : ""} onClick={() => setMillage((prev) => ({...prev, from: 150000}))}>150,000</li>}
                            {millage.to > 180000 && <li className={millage.from == 180000 ? "active" : ""} onClick={() => setMillage((prev) => ({...prev, from: 180000}))}>180,000</li>}
                            {millage.to > 210000 && <li className={millage.from == 210000 ? "active" : ""} onClick={() => setMillage((prev) => ({...prev, from: 210000}))}>210,000</li>}
                        </ul>
                        <ul className="range-selector-dropdown-column">
                            {millage.from < 30000 && <li className={millage.to == 30000 ? "active" : ""} onClick={() => setMillage((prev) => ({...prev, to: 30000}))}>30,000</li>}
                            {millage.from < 60000 && <li className={millage.to == 60000 ? "active" : ""} onClick={() => setMillage((prev) => ({...prev, to: 60000}))}>60,000</li>}
                            {millage.from < 90000 && <li className={millage.to == 90000 ? "active" : ""} onClick={() => setMillage((prev) => ({...prev, to: 90000}))}>90,000</li>}
                            {millage.from < 120000 && <li className={millage.to == 120000 ? "active" : ""} onClick={() => setMillage((prev) => ({...prev, to: 120000}))}>120,000</li>}
                            {millage.from < 150000 && <li className={millage.to == 150000 ? "active" : ""} onClick={() => setMillage((prev) => ({...prev, to: 150000}))}>150,000</li>}
                            {millage.from < 180000 && <li className={millage.to == 180000 ? "active" : ""} onClick={() => setMillage((prev) => ({...prev, to: 180000}))}>180,000</li>}
                            {millage.from < 210000 && <li className={millage.to == 210000 ? "active" : ""} onClick={() => setMillage((prev) => ({...prev, to: 210000}))}>210,000</li>}
                            {millage.from < 999999 && <li className={millage.to == 999999 ? "active" : ""} onClick={() => setMillage((prev) => ({...prev, to: 999999}))}>999,999</li>}
                        </ul>
                    </div>
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <button className="save-millage-button" onClick={() => {
                            setFormData({type: "millage_from", payload: millage.from});
                            setFormData({type: "millage_to", payload: millage.to});
                            setShowDropdown(false);
                        }}>შენახვა</button>
                    </div>
                </div>
            </OutsideClickHandler>}
        </div>
    )
}

export default MillageSelector