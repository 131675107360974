import { Link, NavLink } from "react-router-dom/cjs/react-router-dom.min";
import "./AsideModal.css";

const AsideModal = () => {
    return(
        <div className="aside-modal-container">
            <div><img src="/static/icons/logo.png" alt="admin-logo" className="admin-logo-icon"/></div>
            <nav className="admin-page-navigation-container">
                <NavLink activeClassName="active-admin-nav" to="/admin-dashboard/lead-forms">Lead Form</NavLink>
                <NavLink activeClassName="active-admin-nav" to="/admin-dashboard/users">Users</NavLink>
                <NavLink activeClassName="active-admin-nav" to="/admin-dashboard/transactions">Transactions</NavLink>
                <NavLink activeClassName="active-admin-nav" to="/admin-dashboard/consultation">Consultation</NavLink>
                <NavLink activeClassName="active-admin-nav" to="/admin-dashboard/bids">Bids</NavLink>
                <NavLink activeClassName="active-admin-nav" to="/admin-dashboard/buy-now">Buy Now Vehicles</NavLink>
                <NavLink activeClassName="active-admin-nav" to="/admin-dashboard/purchase-history">Purchase History</NavLink>
                <NavLink activeClassName="active-admin-nav" to="/admin-dashboard/dealer-forms">Dealer Forms</NavLink>
                <NavLink activeClassName="active-admin-nav" to="/admin-dashboard/subscriptions">Subscriptions</NavLink>
            </nav>
            <div className="action-buttons">
                <button className="admin-logout-button">Logout</button>
            </div>
        </div>
    )
}

export default AsideModal