import "./AutoAuctionBanner.css";
import {Link} from "react-router-dom";

const AutoAuctionBanner = () => {
    return(
        <>
            <div className="auto-auction-banner-container">
                <div className="auto-auction-banner-column-left">
                    <img src="/static/auto-auction-banner.png" alt="auto auction banner"/>
                    <img src="/static/auto-auction-banner-2.png" alt="auto auction banner"/>
                </div>
                <div className="auto-auction-banner-column-right">
                    <h3 className="auto-auction-banner-title">საუკეთესო ავტო აუქციონი საქართველოში</h3>
                    <p className="auto-auction-banner-subtitle">Carspace - ის ერთ-ერთი მთავარი კონკურენტული უპირატესობა სწორედ გამორჩეულად დაბალი ფასია</p>
                    <div className="auto-auction-banner-bullet">
                        <img src="/static/icons/checkbox.svg"/>
                        <p>სხვა კომპანიებთან შედარებით ჩვენი დახმარებით საშუალოდ 30%-ით უფრო იაფად შეგიძლიათ ჩამოიყვანოთ ავტომობილი.</p>
                    </div>
                    <div className="auto-auction-banner-bullet">
                        <img src="/static/icons/checkbox.svg"/>
                        <p>ჩვენი სპეციალისტები გაგიწევენ მაღალპროფესიონალურ კონსულტაციას ნებისმიერ თქვენთვის საინტერესო საკითხზე.</p>
                    </div>
                    <div className="auto-auction-banner-bullet">
                        <img src="/static/icons/checkbox.svg"/>
                        <p>Carspace - ში ერთგულად ვინარჩუნებთ პრინციპს, რომ ვიყოთ საუკეთესო შეთავაზების გარანტი ბაზარზე.</p>
                    </div>
                    <Link className="start-using-auction-tool" to="/catalogue">დაწყება</Link>
                </div>
            </div>
        </>
    )
}

export default AutoAuctionBanner