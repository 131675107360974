/* eslint-disable */
import { useEffect, useState } from "react";
import "./Header.css"
import {Link} from 'react-router-dom';
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import Auth from "../../pages/Auth";
import Signup from "../../pages/Signup";
import { useDispatch, useSelector } from "react-redux";
import LoggedInButton from "./LoggedInButton";
import OutsideClickHandler from "react-outside-click-handler";
import language from "../../data/landuage";
import { usersActions } from "../../store/user";

const Header = () => {
    const [showLanguagesDropdown, setShowLanguagesDropdown] = useState(false);
    const [showAuth, setShowAuth] = useState(false);
    const [showSignup, setShowSignup] = useState(false);
    const user = useSelector((state) => state.user);
    const lang = user.language;
    const dispatch = useDispatch();

    const changeLanguage = (selected) => {
        dispatch(usersActions.setLanguage(selected))
    }

    return(
        <header className="header-container">
            <div className="header-content-head">
                <div className="header-content">
                    <div className="d-flex flex-center-start">
                        <Link to="/"><img src="/static/icons/logo.png" className="header-logo-icon" alt="Logo" /></Link>
                        <div className="form-group-searchbox">
                            <img src="/static/icons/loop.png" className="header-loop" alt="Looop"/>
                            <input type="text" className="header-search-input-box" placeholder={language.standard[lang].search}/>
                        </div>
                    </div>
                    <div className="d-flex flex-center-enn">
                        {user.isLoggedIn == false && <button onClick={() => setShowAuth(true)} className="login-button"><img src="/static/icons/user.svg" alt="profile"/><span>{language.header[lang].auth}</span></button>}
                        {user.isLoggedIn == true && <LoggedInButton />}
                        <div className="language-switcher-container">
                            <div className="selected-language-box" onClick={() => setShowLanguagesDropdown(!showLanguagesDropdown)}>
                                <div className="selected-language-content">
                                    {
                                        lang == "en" ? (
                                            <>
                                                <img src="/static/icons/eng-flag.svg" alt="geo-flag" />
                                                <p>English</p>
                                            </>
                                        ) : (
                                            <>
                                                <img src="/static/icons/geo-flag.svg" alt="geo-flag" />
                                                <p>ქართული</p>
                                            </>
                                        )
                                    }
                                </div>
                                <img src="/static/icons/down-icon.svg" alt="down" />
                            </div>
                            {showLanguagesDropdown && <OutsideClickHandler onOutsideClick={() => setShowLanguagesDropdown(false)}>
                                <div className="choose-language-dropdown">
                                    <div className="language-list-card" onClick={() => {
                                            setShowLanguagesDropdown(false)
                                            changeLanguage("geo");
                                        }
                                    }>
                                        <img src="/static/icons/geo-flag.svg" alt="geo-flag" />
                                        <p>ქართული</p>
                                    </div>
                                    <div className="language-list-card" onClick={() => {
                                            setShowLanguagesDropdown(false)
                                            changeLanguage("en");
                                        }
                                    }>
                                        <img src="/static/icons/eng-flag.svg" alt="en-flag" />
                                        <p>English</p>
                                    </div>
                                </div>    
                            </OutsideClickHandler>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-divider"></div>
            <div className="navigation-container">
                <nav className="navbar-content">
                    <ul className="navbar-list">
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/">{language.header[lang].home}</NavLink></li>
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/catalogue">{language.header[lang].catalogue}</NavLink></li>
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/about-us">{language.header[lang].about}</NavLink></li>
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/blogs">{language.header[lang].blog}</NavLink></li>
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/calculator">{language.header[lang].calculator}</NavLink></li>
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/contact">{language.header[lang].contact}</NavLink></li>
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/dealer-page">{language.header[lang].dealerPage}</NavLink></li>
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/auto-market">{language.header[lang].autoMarket}</NavLink></li>
                        {/* <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/admin/blogs">ბლოგის დამატება</NavLink></li> */}
                    </ul>
                </nav>
            </div>
            {showAuth && <Auth setShowAuth={setShowAuth} setShowSignup={setShowSignup} />}
            {showSignup && <Signup setShowAuth={setShowAuth} setShowSignup={setShowSignup} />}
        </header>
    )
}

export default Header