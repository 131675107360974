import { createSlice } from "@reduxjs/toolkit";

const initialUsers = {
    isLoggedIn: null,
    username: "",
    name: "",
    surname: "",
    email: "",
    role: "",
    gender: "",
    token: null,
    phone: "",
    language: "geo",
}

const user = createSlice({
    name: "users",
    initialState: initialUsers,
    reducers: {
        changeIsLoggedIn(state, action){
            state.isLoggedIn = action.payload
        },
        saveUserData(state, action){
            state.role = action.payload.role;
            state.username = action.payload.username;
            state.name = action.payload.name;
            state.surname = action.payload.surname;
            state.email = action.payload.email;
            state.gender = action.payload.gender;
            state.phone = action.payload.phone;
        },
        setToken(state, action){
            state.token = action.payload
        },
        setLanguage(state, action){
            state.language = action.payload;
        }
    }
})

export const usersActions = user.actions;

export default user.reducer