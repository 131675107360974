import { useEffect, useState } from "react"
import axios from "axios";
import { core } from "../../../core";

const Transactions = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        setIsLoading(true);
        setData([]);
        axios(`/api/transactions?limit=${limit}&page=${page}&keyword=${keyword}&status=all&transaction_type=all`)
            .then((res) => {
                setData(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }, [keyword])

    return(
        <div className="admin-pager-container">
            <h3 className="admin-pages-title">Transactions</h3>
            <div className="data-table-filter-container">
                <div className="data-table-filter-sides">
                    <input type="text" placeholder="Search by keyword" className="search-by-keyword-box" onKeyDown={(e) => e.keyCode == 13 && setKeyword(searchFieldValue)} value={searchFieldValue} onChange={(e) => setSearchFieldValue(e.target.value)} />
                    <img onClick={() => setKeyword(searchFieldValue)} className="search-by-keyword-loop" src="/static/icons/loop.png"/>
                </div>
                <div className="data-table-filter-sides">
                    
                </div>
            </div>
            <table className="data-table-container">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Method</th>
                        <th>Status</th>
                        <th>Receiver</th>
                        <th>Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading && <tr><td colSpan={10}><div className="loader-container"><div className="data-loader"></div></div></td></tr>}
                    {(!isLoading && data.length == 0) && <tr><td style={{textAlign: "center"}} colSpan={10}>Records not found</td></tr>}
                    {
                        data.map((item) => {
                            return(
                                <tr key={item.id}>
                                    <td>{item.transaction_id}</td>
                                    <td>{item.transaction_type}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.payment_method}</td>
                                    <td>{item.status}</td>
                                    <td>{item.receiver}</td>
                                    <td>{core.utcTimeToReal(item.date)}</td>
                                    <td>
                                        more
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Transactions