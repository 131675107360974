import { useSelector } from "react-redux";
import language from "../../../data/landuage";
import "./VehicleTypeSelector.css"
import {Link} from 'react-router-dom';

const VehicleTypeSelector = () => {
    const lang = useSelector((state) => state.user.language);

    return(
        <div className="vehicle-type-selector-container">
            <div className="vehicle-type-selector-head">
                <h3 className="vehicle-type-selector-title">{language.home[lang].chooseCar}</h3>
                <Link className={"view-all-button"} to="/catalogue">{language.standard[lang].viewAll}</Link>
            </div>
            <div className="vehicle-type-selector-items">
                <div className="vehicle-type-selector-item" style={{backgroundImage: `url('/static/suv.png')`}}>
                    <p className="vehicle-type-selector-item-counter">23 cars</p>
                    <p className="vehicle-type-selector-item-type">{language.home[lang].suv}</p>
                </div>
                <div className="vehicle-type-selector-item" style={{backgroundImage: `url('/static/sedan.png')`}}>
                    <p className="vehicle-type-selector-item-counter">60 cars</p>
                    <p className="vehicle-type-selector-item-type">{language.home[lang].sedan}</p>
                </div>
                <div className="vehicle-type-selector-item" style={{backgroundImage: `url('/static/coupe.png')`}}>
                    <p className="vehicle-type-selector-item-counter">40 cars</p>
                    <p className="vehicle-type-selector-item-type">{language.home[lang].coupe}</p>
                </div>
                <div className="vehicle-type-selector-item" style={{backgroundImage: `url('/static/hybrid.png')`}}>
                    <p className="vehicle-type-selector-item-counter">38 cars</p>
                    <p className="vehicle-type-selector-item-type">{language.home[lang].hybrid}</p>
                </div>
                <div className="vehicle-type-selector-item" style={{backgroundImage: `url('/static/compact.png')`}}>
                    <p className="vehicle-type-selector-item-counter">88 cars</p>
                    <p className="vehicle-type-selector-item-type">{language.home[lang].compact}</p>
                </div>
            </div>
        </div>
    )
}

export default VehicleTypeSelector