import "./Banner.css"

const Banner = () => {
    return(
        <div className="banner-content-container">
            <video width="100%"autoPlay muted loop className="main-page-video-banner">
                <source src="/static/video/final_2.mp4" type="video/mp4"></source>
            </video>
        </div>
    )
}

export default Banner