import axios from "axios";
import { useEffect, useState } from "react"
import { Link } from "react-router-dom/cjs/react-router-dom";
import NewsCard from "../components/MainPage/NewsCard/NewsCard";

const BlogDetails = () => {
    const [id, setId] = useState(window.location.pathname.slice(6));
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [blogsList, setBlogsList] = useState([]);
    const [currentBlogIndex, setCurrentBlogIndex] = useState(0);
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: for smooth scrolling
        });
        axios.get('/api/latest/blogs?limit=3')
            .then((res) => {
                setBlogs(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    useEffect(() => {
        axios.get(`/api/blogs/${id}`)
            .then((res) => {
                setData(res.data.data[0]);
            })
            .catch(() => {

            })

        axios.get('/api/bloglist-id')
            .then((res) => {
                const ids = res.data.data.map((i) => i.id);
                setBlogsList(ids);
                const index = ids.indexOf(parseInt(id));
                setCurrentBlogIndex(index);
            })
            .catch((err) => {

            })
    }, [id])

    return(
        <div className="blog-container">
            <div className="pager-history"><Link to="/">მთავარი</Link><span> / </span><Link href="/blog">ბლოგი</Link><span> / </span><span>{data?.title}</span></div>
            <h2 className="blog-details-title">{data?.title}</h2>
            <div style={{textAlign: "left"}} dangerouslySetInnerHTML={{__html: data?.html}}></div>
            <div className="blogs-switcher">
                {currentBlogIndex != 0 ? <Link onClick={() => setId(() => blogsList[currentBlogIndex - 1])} to={`/blog/${blogsList[currentBlogIndex - 1]}`}>
                    <svg style={{marginRight: "8px"}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M4.296 12L12.788 3.273C12.8579 3.20267 12.9132 3.11917 12.9506 3.02734C12.9881 2.93551 13.0069 2.83717 13.0061 2.738C13.0053 2.63884 12.9848 2.54082 12.9458 2.44962C12.9069 2.35843 12.8502 2.27586 12.7791 2.2067C12.7081 2.13754 12.624 2.08317 12.5318 2.04672C12.4395 2.01027 12.341 1.99248 12.2418 1.99437C12.1427 1.99626 12.0449 2.01779 11.9541 2.05772C11.8633 2.09765 11.7814 2.15519 11.713 2.227L2.713 11.477C2.57678 11.617 2.50056 11.8047 2.50056 12C2.50056 12.1953 2.57678 12.383 2.713 12.523L11.713 21.773C11.7814 21.8448 11.8633 21.9023 11.9541 21.9423C12.0449 21.9822 12.1427 22.0037 12.2418 22.0056C12.341 22.0075 12.4395 21.9897 12.5318 21.9533C12.624 21.9168 12.7081 21.8625 12.7791 21.7933C12.8502 21.7241 12.9069 21.6416 12.9458 21.5504C12.9848 21.4592 13.0053 21.3612 13.0061 21.262C13.0069 21.1628 12.9881 21.0645 12.9506 20.9727C12.9132 20.8808 12.8579 20.7973 12.788 20.727L4.296 12Z" fill="black"/>
                    </svg>
                    <span>წინა პოსტი</span>
                </Link> : <div>
                    <svg style={{marginRight: "8px"}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M4.296 12L12.788 3.273C12.8579 3.20267 12.9132 3.11917 12.9506 3.02734C12.9881 2.93551 13.0069 2.83717 13.0061 2.738C13.0053 2.63884 12.9848 2.54082 12.9458 2.44962C12.9069 2.35843 12.8502 2.27586 12.7791 2.2067C12.7081 2.13754 12.624 2.08317 12.5318 2.04672C12.4395 2.01027 12.341 1.99248 12.2418 1.99437C12.1427 1.99626 12.0449 2.01779 11.9541 2.05772C11.8633 2.09765 11.7814 2.15519 11.713 2.227L2.713 11.477C2.57678 11.617 2.50056 11.8047 2.50056 12C2.50056 12.1953 2.57678 12.383 2.713 12.523L11.713 21.773C11.7814 21.8448 11.8633 21.9023 11.9541 21.9423C12.0449 21.9822 12.1427 22.0037 12.2418 22.0056C12.341 22.0075 12.4395 21.9897 12.5318 21.9533C12.624 21.9168 12.7081 21.8625 12.7791 21.7933C12.8502 21.7241 12.9069 21.6416 12.9458 21.5504C12.9848 21.4592 13.0053 21.3612 13.0061 21.262C13.0069 21.1628 12.9881 21.0645 12.9506 20.9727C12.9132 20.8808 12.8579 20.7973 12.788 20.727L4.296 12Z" fill="black"/>
                    </svg>
                    <span>წინა პოსტი</span>
                </div>}
                {currentBlogIndex < blogsList.length - 1 ? <Link onClick={() => setId(blogsList[currentBlogIndex + 1])} to={`/blog/${blogsList[currentBlogIndex + 1]}`}>
                    <span>შემდეგი პოსტი</span>
                    <svg style={{marginLeft: "8px"}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M19.7039 12L11.212 3.273C11.142 3.20267 11.0868 3.11917 11.0493 3.02734C11.0119 2.93551 10.993 2.83717 10.9939 2.738C10.9947 2.63884 11.0152 2.54082 11.0541 2.44962C11.0931 2.35843 11.1497 2.27586 11.2208 2.2067C11.2919 2.13754 11.376 2.08317 11.4682 2.04672C11.5604 2.01027 11.659 1.99248 11.7581 1.99437C11.8573 1.99626 11.9551 2.01779 12.0458 2.05772C12.1366 2.09765 12.2186 2.15519 12.2869 2.227L21.2869 11.477C21.4232 11.617 21.4994 11.8047 21.4994 12C21.4994 12.1953 21.4232 12.383 21.2869 12.523L12.2869 21.773C12.2186 21.8448 12.1366 21.9023 12.0458 21.9423C11.9551 21.9822 11.8573 22.0037 11.7581 22.0056C11.659 22.0075 11.5604 21.9897 11.4682 21.9533C11.376 21.9168 11.2919 21.8625 11.2208 21.7933C11.1497 21.7241 11.0931 21.6416 11.0541 21.5504C11.0152 21.4592 10.9947 21.3612 10.9939 21.262C10.993 21.1628 11.0119 21.0645 11.0493 20.9727C11.0868 20.8808 11.142 20.7973 11.212 20.727L19.7039 12Z" fill="black"/>
                    </svg>
                </Link> : <div disabled >
                    <span>შემდეგი პოსტი</span>
                    <svg style={{marginLeft: "8px"}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M19.7039 12L11.212 3.273C11.142 3.20267 11.0868 3.11917 11.0493 3.02734C11.0119 2.93551 10.993 2.83717 10.9939 2.738C10.9947 2.63884 11.0152 2.54082 11.0541 2.44962C11.0931 2.35843 11.1497 2.27586 11.2208 2.2067C11.2919 2.13754 11.376 2.08317 11.4682 2.04672C11.5604 2.01027 11.659 1.99248 11.7581 1.99437C11.8573 1.99626 11.9551 2.01779 12.0458 2.05772C12.1366 2.09765 12.2186 2.15519 12.2869 2.227L21.2869 11.477C21.4232 11.617 21.4994 11.8047 21.4994 12C21.4994 12.1953 21.4232 12.383 21.2869 12.523L12.2869 21.773C12.2186 21.8448 12.1366 21.9023 12.0458 21.9423C11.9551 21.9822 11.8573 22.0037 11.7581 22.0056C11.659 22.0075 11.5604 21.9897 11.4682 21.9533C11.376 21.9168 11.2919 21.8625 11.2208 21.7933C11.1497 21.7241 11.0931 21.6416 11.0541 21.5504C11.0152 21.4592 10.9947 21.3612 10.9939 21.262C10.993 21.1628 11.0119 21.0645 11.0493 20.9727C11.0868 20.8808 11.142 20.7973 11.212 20.727L19.7039 12Z" fill="black"/>
                    </svg>
                </div>}
            </div>
            <div className="news-card-slider">
                <h3 className="section-title">მსგავსი სიახლეები</h3>
                <div className="main-page-news-card-list">
                    {
                        blogs.length > 0 && (
                            blogs.map((b) => {
                                return <div onClick={() => setId(b.id)}><NewsCard data={b}/></div>
                            })
                        )
                    }
                    {
                        blogs.length == 0 && <h3>სიახლეები ვერ მოიძებნა</h3>
                    }
                </div>
            </div>
        </div>
    )
}

export default BlogDetails