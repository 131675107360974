import { useEffect, useState } from "react"
import "./Notification.css"

const Notification = ({message, title, status, hide}) => {
    const [backgroundColor, setBackgroundColor] = useState("");
    const [textColor, setTextColor] = useState("");

    useEffect(() => {
        if(status == "success"){
            setBackgroundColor("success-notification");
            setTextColor("notification-success-text");
        }
        if(status == "error"){
            setBackgroundColor("error-notification");
            setTextColor("notification-error-text");
        }
        if(status == "info"){
            setBackgroundColor("info-notification");
            setTextColor("notification-info-text");
        }
    }, [])

    return(
        <div className={`notification-box ${backgroundColor}`}>
            {
                status == "success" && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <mask id="mask0_1049_36" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <rect width="24" height="24" fill="#D9D9D9"/>
                    </mask>
                    <g mask="url(#mask0_1049_36)">
                    <path d="M9.55012 17.575C9.41679 17.575 9.29179 17.554 9.17512 17.512C9.05846 17.4707 8.95012 17.4 8.85012 17.3L4.55012 13C4.36679 12.8167 4.27912 12.579 4.28712 12.287C4.29579 11.9957 4.39179 11.7583 4.57512 11.575C4.75846 11.3917 4.99179 11.3 5.27512 11.3C5.55846 11.3 5.79179 11.3917 5.97512 11.575L9.55012 15.15L18.0251 6.67499C18.2085 6.49166 18.4461 6.39999 18.7381 6.39999C19.0295 6.39999 19.2668 6.49166 19.4501 6.67499C19.6335 6.85833 19.7251 7.09566 19.7251 7.38699C19.7251 7.67899 19.6335 7.91666 19.4501 8.09999L10.2501 17.3C10.1501 17.4 10.0418 17.4707 9.92512 17.512C9.80846 17.554 9.68346 17.575 9.55012 17.575Z" fill="#73B76F"/>
                    </g>
                </svg>
            }
            {
                status == "error" && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9 1.5C15.3 1.65 17.55 2.85 19.2 4.5C21.15 6.6 22.2 9.15 22.2 12.15C22.2 14.55 21.3 16.8 19.8 18.75C18.3 20.55 16.2 21.9 13.8 22.35C11.4 22.8 9 22.5 6.9 21.3C4.8 20.1 3.15 18.3 2.25 16.05C1.35 13.8 1.2 11.25 1.95 9C2.7 6.6 4.05 4.65 6.15 3.3C8.1 1.95 10.5 1.35 12.9 1.5ZM13.65 20.85C15.6 20.4 17.4 19.35 18.75 17.7C19.95 16.05 20.7 14.1 20.55 12C20.55 9.6 19.65 7.2 18 5.55C16.5 4.05 14.7 3.15 12.6 3C10.65 2.85 8.55 3.3 6.9 4.5C5.25 5.7 4.05 7.35 3.45 9.45C2.85 11.4 2.85 13.5 3.75 15.45C4.65 17.4 6 18.9 7.8 19.95C9.6 21 11.7 21.3 13.65 20.85ZM11.85 11.25L15.45 7.5L16.5 8.55L12.9 12.3L16.5 16.05L15.45 17.1L11.85 13.35L8.25 17.1L7.2 16.05L10.8 12.3L7.2 8.55L8.25 7.5L11.85 11.25Z" fill="#E53E33"/>
                </svg>
            }
            {
                status == "info" && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 17C12.2833 17 12.521 16.904 12.713 16.712C12.905 16.52 13.0007 16.2827 13 16C12.9993 15.7173 12.9033 15.48 12.712 15.288C12.5207 15.096 12.2833 15 12 15C11.7167 15 11.4793 15.096 11.288 15.288C11.0967 15.48 11.0007 15.7173 11 16C10.9993 16.2827 11.0953 16.5203 11.288 16.713C11.4807 16.9057 11.718 17.0013 12 17ZM11 13H13V7H11V13ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88334 20.6867 5.825 19.9743 4.925 19.075C4.025 18.1757 3.31267 17.1173 2.788 15.9C2.26333 14.6827 2.00067 13.3827 2 12C1.99933 10.6173 2.262 9.31733 2.788 8.1C3.314 6.88267 4.02633 5.82433 4.925 4.925C5.82367 4.02567 6.882 3.31333 8.1 2.788C9.318 2.26267 10.618 2 12 2C13.382 2 14.682 2.26267 15.9 2.788C17.118 3.31333 18.1763 4.02567 19.075 4.925C19.9737 5.82433 20.6863 6.88267 21.213 8.1C21.7397 9.31733 22.002 10.6173 22 12C21.998 13.3827 21.7353 14.6827 21.212 15.9C20.6887 17.1173 19.9763 18.1757 19.075 19.075C18.1737 19.9743 17.1153 20.687 15.9 21.213C14.6847 21.739 13.3847 22.0013 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="#FFB900"/>
                </svg>
            }
            <div className="notification-body-content">
                <p className={`notification-title ${textColor}`}>{title}</p>
                <p className={`notification-text-body ${textColor}`}>{message}</p>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => hide()}>
                <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#999999"/>
            </svg>
        </div>
    )
}

export default Notification