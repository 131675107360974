import { useState, useEffect } from "react";
import CalculatorDropdown from "../CalculatorDropdown/CalculatorDropdown";
import "./TransportationCalculator.css";
import axios from "axios";
import language from "../../../data/landuage";
import { useSelector } from "react-redux";

const TransportationCalculator = () => {
    const [formData, setFormData] = useState({auction: "", location: "", destination: "", port: ""});
    const [formList, setFormList] = useState({
        auction: [{name: "Copart", value: "copart"}, {name: "IAAI", value: "iaai"}],
        location: [],
        port: [],
        destination: [{name: "ფოთი", value: "ფოთი"}]
    });
    const [location, setLocation] = useState([]);
    const [shownDropdown, setShownDropdown] = useState("");
    const [sum, setSum] = useState({land: 0, sea: 0, sum: 0});

    const [data, setData] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const lang = useSelector((state) => state.user.language);

    useEffect(() => {
        axios('/api/calculator')
            .then((res) => {
                setData([...res.data.data])
            })
            .catch(() => {

            })
    }, [])

    return(
        <div className="main-page-transportation-calculator-container">
            <div className="main-page-transportation-calculator-banner"></div>
            <div className="calculator-content-box">
                <h2>{language.home[lang].calculator.title}</h2>
                <p className="main-page-transportation-calculator-subtitle">{language.home[lang].calculator.subtitle}</p>
                <div className="main-page-transportation-calculator-form">
                    <CalculatorDropdown 
                        label={language.home[lang].calculator.auction} 
                        defaultValue={language.home[lang].calculator.selectAuction} 
                        selected={formData.auction} 
                        listValue={formList.auction}
                        setter={(e) => {
                            setFormData((prev) => ({...prev, auction: e}));
                            const list = data.filter((item) => item.auction == e);
                            const changeStructure = list.map((item) => ({name: item.city, value: item.city}));
                            setLocation(changeStructure)
                        }}
                        shownDropdown={shownDropdown.auction}
                        setShownDropdown={() => {
                            setShownDropdown((prev) => ({port: false, location:false, destination: false,auction: !prev.auction}))
                        }}
                    />
                    <CalculatorDropdown 
                        label={language.home[lang].calculator.location} 
                        defaultValue={language.home[lang].calculator.selectLocation} 
                        selected={formData.location} 
                        listValue={location}
                        setter={(e) => {
                            setFormData((prev) => ({...prev, location: e}));
                            const list = data.filter((item) => item.city == e);
                            const changeStructure = list.map((item) => ({name: item.port, value: item.port}));
                            setFormList((prev) => ({...prev, port: [...changeStructure]}))
                        }}
                        shownDropdown={shownDropdown.location}
                        setShownDropdown={() => setShownDropdown((prev) => ({port: false, auction:false, destination: false, location: !prev.location}))}
                        withInput={true}
                        inputPlaceHolder={"ჩაწერეთ საძიებო სიტყვა"}
                    />
                    <CalculatorDropdown 
                        label={language.home[lang].calculator.port} 
                        defaultValue={language.home[lang].calculator.selectPort} 
                        selected={formData.port} 
                        listValue={formList.port}
                        setter={(e) => {
                            setFormData((prev) => ({...prev, port: e}));

                        }}
                        shownDropdown={shownDropdown.port}
                        setShownDropdown={() => setShownDropdown((prev) => ({location: false, auction:false, destination: false, port: !prev.port}))}
                    />
                    <CalculatorDropdown 
                        label={language.home[lang].calculator.destination} 
                        defaultValue={language.home[lang].calculator.selectDestination} 
                        selected={formData.destination} 
                        listValue={formList.destination}
                        setter={(e) => setFormData((prev) => ({...prev, destination: e}))}
                        shownDropdown={shownDropdown.destination}
                        setShownDropdown={() => setShownDropdown((prev) => ({port: false, auction:false, location: false, destination: !prev.destination}))}
                    />
                    <div className="calculator-result-content">
                        <div className="calculator-result-price">
                            <p className="calculator-result-title">{language.home[lang].calculator.domesticShipping}</p>
                            <p className="calculator-result-value">{sum.land}$</p>
                        </div>
                        <div className="calculator-result-price">
                            <p className="calculator-result-title">{language.home[lang].calculator.shipping}</p>
                            <p className="calculator-result-value">{sum.sea}$</p>
                        </div>
                        <div className="calculator-result-price">
                            <p className="calculator-result-title">{language.home[lang].calculator.total}</p>
                            <p className="calculator-result-value">{sum.sum}$</p>
                        </div>
                    </div>
                    <button className="main-page-calculate-button" onClick={() => {
                        const list = data.filter((item) => item.city == formData.location);
                        setSum({land: list[0].land, sea: list[0].price, sum: parseInt(list[0].price) + parseInt(list[0].land)})
                    }}>
                        <div className="main-page-calculate-button-content">
                            <p>{language.home[lang].calculator.calculate}</p>
                            <img src="/static/icons/calculator.svg" alt="calculator" />
                        </div>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default TransportationCalculator